import { useAuthInfo, useLogoutFunction } from '@propelauth/react';
import { ChevronRight, LogOut } from 'lucide-react';
import { UIMatch, useMatches, useNavigate } from 'react-router';

import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { RouteLoaderCommonData } from '~/ts-types';

export default function TopHeader() {
  const authInfo = useAuthInfo();
  const logoutFunction = useLogoutFunction();
  const navigate = useNavigate();

  const matches = useMatches() as UIMatch<RouteLoaderCommonData>[];

  const renderBreadcrumbBtn = (title: string, navStep?: number) => (
    <Button
      variant='ghost'
      size='sm'
      onClick={() => {
        if (navStep) {
          navigate(navStep);
        }
      }}
    >
      {title}
    </Button>
  );

  const renderBreadcrumb = () => {
    const lastMatch = matches[matches.length - 1];
    if (!lastMatch.data || !lastMatch.data.breadcrumb) {
      return null;
    }

    const { breadcrumb } = lastMatch.data;

    // /vulnerabilities/:id
    if (lastMatch.id === 'routes/vulnerabilities_.$id') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Vulnerabilities', -1)}
          <ChevronRight />
          {renderBreadcrumbBtn(breadcrumb.title)}
        </div>
      );
    }

    // /data-elements/:id
    if (lastMatch.id === 'routes/data-elements_.$id') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Data Elements', -1)}
          <ChevronRight />
          {renderBreadcrumbBtn(breadcrumb.title)}
        </div>
      );
    }

    // /sanitizers/:id
    if (lastMatch.id === 'routes/sanitizers_.$id') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Sanitizers', -1)}
        </div>
      );
    }

    // /sensitive-datamap/occurrences
    if (lastMatch.id === 'routes/sensitive-datamap_.occurrences') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Sensitive Datamap', -1)}
          <ChevronRight />
          {renderBreadcrumbBtn(breadcrumb.title)}
        </div>
      );
    }

    // /sensitive-datamap/occurrences/:id
    if (lastMatch.id === 'routes/sensitive-datamap_.occurrences_.$id') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Sensitive Datamap', -2)}
          <ChevronRight />
          {renderBreadcrumbBtn('Occurrences', -1)}
          <ChevronRight />
          {renderBreadcrumbBtn(breadcrumb.title)}
        </div>
      );
    }

    // /sensitive-datamap/visualization/
    if (lastMatch.id === 'routes/sensitive-datamap_.visualization') {
      return (
        <div className='flex items-center'>
          {renderBreadcrumbBtn('Sensitive Datamap', -1)}
          <ChevronRight />
          {renderBreadcrumbBtn(breadcrumb.title)}
        </div>
      );
    }

    return (
      <Button
        variant='ghost'
        size='sm'
        onClick={() => navigate(breadcrumb.url, { replace: true })}
      >
        {breadcrumb.title}
      </Button>
    );
  };

  return (
    <header className='fixed left-60 right-0 top-0 z-50 h-14 bg-white shadow-md'>
      <div className='container flex items-center justify-between px-4 py-3'>
        <div className=''>{renderBreadcrumb()}</div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className='flex items-center gap-1'>
              <Avatar className='h-8 w-8'>
                <AvatarImage src={authInfo.user?.pictureUrl} />
                <AvatarFallback>{`${authInfo.user?.firstName?.charAt(0)} ${authInfo.user?.lastName?.charAt(0)}`}</AvatarFallback>
              </Avatar>
              <Button variant='ghost' size='sm'>
                {`${authInfo.user?.firstName} ${authInfo.user?.lastName}`}
              </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-56'>
            <DropdownMenuItem
              onClick={() => logoutFunction(true)}
              className='cursor-pointer'
            >
              <LogOut className='mr-2 h-4 w-4' />
              <span>Log out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}
